"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var core_1 = require('@angular/core');
var platform_browser_1 = require('@angular/platform-browser');
var config_1 = require('../../config/config');
var click_block_1 = require('../../util/click-block');
var platform_1 = require('../../platform/platform');
/**
 * App utility service.  Allows you to look up components that have been
 * registered using the [Id directive](../Id/).
 */
var IonicApp = (function () {
    function IonicApp(_config, _clickBlock, platform) {
        var _this = this;
        this._config = _config;
        this._clickBlock = _clickBlock;
        this._disTime = 0;
        this._scrollTime = 0;
        this._title = '';
        this._titleSrv = new platform_browser_1.Title();
        this._isProd = false;
        this._rootNav = null;
        platform.backButton.subscribe(function () {
            var activeNav = _this.getActiveNav();
            if (activeNav) {
                if (activeNav.length() === 1) {
                    platform.exitApp();
                }
                else {
                    activeNav.pop();
                }
            }
        });
    }
    /**
     * Sets the document title.
     * @param {string} val  Value to set the document title to.
     */
    IonicApp.prototype.setTitle = function (val) {
        if (val !== this._title) {
            this._title = val;
            this._titleSrv.setTitle(val);
        }
    };
    /**
     * Returns if the app has been set to be in be in production mode or not.
     * Production mode can only be set within the config of `@App`. Defaults
     * to `false`.
     * @return {boolean}
     */
    IonicApp.prototype.isProd = function () {
        return this._isProd;
    };
    /**
     * @private
     */
    IonicApp.prototype.setProd = function (val) {
        this._isProd = !!val;
    };
    /**
     * @private
     * Sets if the app is currently enabled or not, meaning if it's
     * available to accept new user commands. For example, this is set to `false`
     * while views transition, a modal slides up, an action-sheet
     * slides up, etc. After the transition completes it is set back to `true`.
     * @param {boolean} isEnabled
     * @param {boolean} fallback  When `isEnabled` is set to `false`, this argument
     * is used to set the maximum number of milliseconds that app will wait until
     * it will automatically enable the app again. It's basically a fallback incase
     * something goes wrong during a transition and the app wasn't re-enabled correctly.
     */
    IonicApp.prototype.setEnabled = function (isEnabled, duration) {
        if (duration === void 0) { duration = 700; }
        this._disTime = (isEnabled ? 0 : Date.now() + duration);
        if (duration > 32 || isEnabled) {
            // only do a click block if the duration is longer than XXms
            this._clickBlock.show(!isEnabled, duration + 64);
        }
    };
    /**
     * @private
     * Boolean if the app is actively enabled or not.
     * @return {boolean}
     */
    IonicApp.prototype.isEnabled = function () {
        return (this._disTime < Date.now());
    };
    /**
     * @private
     */
    IonicApp.prototype.setScrolling = function () {
        this._scrollTime = Date.now();
    };
    /**
     * Boolean if the app is actively scrolling or not.
     * @return {boolean}
     */
    IonicApp.prototype.isScrolling = function () {
        return (this._scrollTime + 64 > Date.now());
    };
    /**
     * @private
     */
    IonicApp.prototype.getActiveNav = function () {
        var nav = this._rootNav || null;
        var activeChildNav;
        while (nav) {
            activeChildNav = nav.getActiveChildNav();
            if (!activeChildNav) {
                break;
            }
            nav = activeChildNav;
        }
        return nav;
    };
    /**
     * @private
     */
    IonicApp.prototype.getRootNav = function () {
        return this._rootNav;
    };
    /**
     * @private
     */
    IonicApp.prototype.setRootNav = function (nav) {
        this._rootNav = nav;
    };
    /**
     * @private
     */
    IonicApp.prototype.getRegisteredComponent = function (cls) {
        // deprecated warning: added 2016-04-28, beta7
        void 0;
    };
    /**
     * @private
     */
    IonicApp.prototype.getComponent = function (id) {
        // deprecated warning: added 2016-04-28, beta7
        void 0;
    };
    /**
     * Set the global app injector that contains references to all of the instantiated providers
     * @param injector
     */
    IonicApp.prototype.setAppInjector = function (injector) {
        this._appInjector = injector;
    };
    /**
     * Get an instance of the global app injector that contains references to all of the instantiated providers
     * @returns {Injector}
     */
    IonicApp.prototype.getAppInjector = function () {
        return this._appInjector;
    };
    IonicApp = __decorate([
        core_1.Injectable(), 
        __metadata('design:paramtypes', [config_1.Config, click_block_1.ClickBlock, platform_1.Platform])
    ], IonicApp);
    return IonicApp;
}());
exports.IonicApp = IonicApp;
